<template>
    <div class="page">
        <div class="hzynav w-1350">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{path: '/'}">首页</el-breadcrumb-item>
                <el-breadcrumb-item :to="{path: '/partner'}">合作伙伴</el-breadcrumb-item>
                <el-breadcrumb-item>海外合作商</el-breadcrumb-item>
                <el-breadcrumb-item>各国家列表</el-breadcrumb-item>
                <el-breadcrumb-item>乌兹别克</el-breadcrumb-item>
                <el-breadcrumb-item>{{title}}</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="conter w-1350">
            <div class="conter-text">合作伙伴介绍</div>
            <div class="conter-p">河南海外仓供应链管理服务有限公司，通过利用海外仓库、展厅、跨境电商平台、当地营销团队等，为出⼝企业提供商品在境外的展示、存储、物流、清关、分销、代理贸易、商超入驻、电商平台运营、供应链金融、商务对接、咨询等外贸综合服务，帮助出口企业快速进入海外市场、建立境外销售网络、实现线上线下的营销渠道、提高本地化竞争优势、扩大出口业务，做到资源整合、抱团出海、防范和降低了企业走出去开展投资与贸易的风险及成本等。

海外仓分销服务目前已在吉尔吉斯、乌兹别克、埃及、尼日利亚、美国、德国、西班牙、南非、格鲁吉亚、乌干达、莫桑比克、蒙古、巴西、澳大利亚、哥斯达黎加、泰国、马来西亚等多个国家建立了海外仓库、展厅、物流清关、分销服务团队等资源，并与多个国家的海外仓、分销渠道构建了良好的合作关系，可以为企业提供全面的商品海外分销服务工作。

海外仓分销服务目前已在吉尔吉斯、乌兹别克、埃及、尼日利亚、美国、德国、西班牙、南非、格鲁吉亚、乌干达、莫桑比克、蒙古、巴西、澳大利亚、哥斯达黎加、泰国、马来西亚等多个国家建立了海外仓库、展厅、物流清关、分销服务团队等资源，并与多个国家的海外仓、分销渠道构建了良好的合作关系，可以为企业提供全面的商品海外分销服务工作。







</div>
            
            
        </div>
    </div>
</template>

<script>
    export default {
        name:'state_list',
        data() {
            return {
                title:''
                
            }
        },
        created() {
            this.title=this.$route.query.title
            console.log(this.$route.query.title)
            
        },
        methods: {    
            // 跳转页面
            goTo(url,title) {
                this.$router.push({ 
                    path: url ,
                    query:{
                        title:title
                    }
                });
            }        
        },
    }
</script>

<style lang="less" scoped>
    .page{background: #F4F4F4;overflow: hidden;}
    .hzynav{margin: 35px auto;padding-left: 15px;box-sizing: border-box;}
    .conter{
        margin: auto;margin-bottom:140px;padding:20px 40px 100px 40px;box-sizing: border-box;background:#fff;
        .conter-text{color: #555555;font-size: 28px;margin-bottom: 50px;text-align: center;}
        .conter-p{color: #666666;font-size: 16px;line-height: 32px; padding: 0 80px;}        
    }

</style>